<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {paginationHelper} from "@/helpers/pagination-helper";
import GlobalCarrierTable from "../../../components/global-carrier-table.vue";

export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    GlobalCarrierTable,
    Layout,
    PageHeader
  },

  data() {
    return {
      form: {
        market: null
      },

      reload: false,
      refs: {}
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Domyślne cenniki",
          active: true
        }
      ]
    },

    changeMarket(value) {
      this.form.market = value
      this.reload = !this.reload
    },

    shouldRenderCommitChanges() {
      if (this.refs["ecat_carrier_table"] && this.refs["ecat_carrier_table"].isShouldUpdate()) {
        return true
      } else if (this.refs["ecat_allegro_smart_carrier_table"] && this.refs["ecat_allegro_smart_carrier_table"].isShouldUpdate()) {
        return true
      } else if (this.refs["oversize_carrier_table"] && this.refs["oversize_carrier_table"].isShouldUpdate()) {
        return true
      }

      return false
    },

    async commitChanges() {
      if (this.refs["ecat_carrier_table"]) {
        await this.refs["ecat_carrier_table"].commitChanges()
      }

      if (this.refs["ecat_allegro_smart_carrier_table"]) {
        await this.refs["ecat_allegro_smart_carrier_table"].commitChanges()
      }

      if (this.refs["oversize_carrier_table"]) {
        await this.refs["oversize_carrier_table"].commitChanges()
      }
    },

    setRef(refName) {
      return (ref) => {
        if (!ref) {
          return
        }

        this.$set(this.refs, refName, ref);
      };
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader title="Domyślne cenniki" :items="getItems()"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-2 mb-4">
                <ecat-multiselect
                    @change-object="value => changeMarket(value)"
                    save-id="id"
                    :custom-label="value => $t(value.i18nTranslation)"
                    label="Rynek"
                    placeholder="Wybierz rynek"
                    fetch-one-url="/market"
                    load-url="/market/pagination"
                    list-name="markets"
                />

                <p v-if="!form.market" class="text-danger">Aby wyświetlić cenniki wybierz rynek</p>
              </div>
            </div>

            <div class="row" v-if="form.market">
              <div class="col-12 col-lg-4">
                <global-carrier-table :market="form.market" title="Cena dostaw ecat (priorytet)" carrier-type="ECAT" :ref="setRef('ecat_carrier_table')" :key="`ecat_carrier_table-${reload}`" />
              </div>

              <div class="col-12 col-lg-4">
                <global-carrier-table :market="form.market" title="Cena dostaw ecat smart (priorytet)" carrier-type="ECAT_ALLEGRO_SMART" :ref="setRef('ecat_allegro_smart_carrier_table')" :key="`ecat_allegro_smart_carrier_table-${reload}`" />
              </div>

              <div class="col-12 col-lg-4">
                <global-carrier-table :market="form.market" title="Gabaryty" carrier-type="OVERSIZE" :ref="setRef('oversize_carrier_table')" :key="`oversize_carrier_table-${reload}`" />
              </div>
            </div>

            <div class="text-center" v-if="form.market">
              <b-button variant="success" :disabled="!shouldRenderCommitChanges()" @click="commitChanges">Zatwierdź zmiany</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
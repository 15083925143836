<template>
  <div>
    <div class="text-center">
      <h4>{{ title }}</h4>
    </div>

    <div class="rectangle-table">

      <template v-if="carriers">
        <div class="rectangle-content">
          <div class="rectangle" v-for="(globalCarrier, index) in carriers" :key="index">

            <div class="ecat-left">
              <div class="button-items">
                <b-button :variant="globalCarrier.active ? 'danger' : 'success'" class="btn-sm" @click="changeActiveState(globalCarrier)">
                  <i class="fa" :class="globalCarrier.active ? 'fa-times' : 'fa-check'"></i>
                </b-button>

                <b-button variant="warning" class="btn-sm" @click="openCarrierModal(globalCarrier)">
                  <i class="fa fa-edit"></i>
                </b-button>
              </div>
            </div>

            <div class="centered-data">
              <template v-if="globalCarrier.carrierName.length > 20">
                <h6 class="text-center">{{ globalCarrier.carrierName }}</h6>
              </template>
              <template v-else>
                <h4 class="text-center">{{ globalCarrier.carrierName }}</h4>
              </template>
            </div>

            <div class="ecat-right">
              <b class="text-warning">Cena: {{ priceHelper.format(globalCarrier.shippingCost, market.currency) }}</b>
            </div>
          </div>
        </div>
      </template>

      <infinite-loading @infinite="loadMore">
        <span slot="no-more">Nie ma więcej elementów.</span>
        <span slot="no-results">Brak wyników. :(</span>
      </infinite-loading>
    </div>

    <div class="text-center py-2">
      <b-button variant="success" @click="openCarrierModal(null)">Dodaj nową opcję</b-button>
    </div>

    <b-modal
        v-if="form.globalCarrier"
        id="modal-1"
        v-model="modals.globalCarrierModal"
        :title="form.globalCarrier.id ? 'Aktualizacja przewoźnika' : 'Tworzenie nowego przewoźnika'"
        title-class="font-18"
        hide-footer
        @hide="hideCarrierModal"
        @esc="hideCarrierModal">
      <form>

        <div class="form-group" v-if="carrierType !== 'ECAT_ALLEGRO_SMART'">
          <ecat-multiselect
              label="Przewoźnik"
              placeholder="Wybierz przewoźnika"
              :set-value="form.globalCarrier.carrierId"
              @change="value => form.globalCarrier.carrierId = value"
              @change-object="object => form.globalCarrier.carrierName = object.name"
              fetch-one-url="/carrier"
              load-url="/carrier/list/pagination"
              query-url="/carrier/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.globalCarrier.carrierId.$error }"
          />
          <div v-if="submitted && !$v.form.globalCarrier.carrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group" v-if="carrierType !== 'ECAT_ALLEGRO_SMART'">
          <ecat-multiselect
              label="Przewoźnik Allegro"
              placeholder="Wybierz przewoźnika Allegro"
              :set-value="form.globalCarrier.allegroCarrierId"
              @change="value => form.globalCarrier.allegroCarrierId = value"
              @change-object="object => changeAllegroCarrier(object)"
              fetch-one-url="/allegro/carrier"
              load-url="/allegro/carrier/list/pagination"
              query-url="/allegro/carrier/list/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.globalCarrier.allegroCarrierId.$error }"
          />
          <div v-if="allegroCarrier" class="text-danger">Cena minimalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMin }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="allegroCarrier" class="text-danger">Cena maksymalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMax }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="submitted && !$v.form.globalCarrier.allegroCarrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>
        <div class="form-group" v-else>
          <ecat-multiselect
              label="Przewoźnik Allegro"
              placeholder="Wybierz przewoźnika Allegro"
              :set-value="form.globalCarrier.allegroCarrierId"
              @change="value => form.globalCarrier.allegroCarrierId = value"
              @change-object="object => changeAllegroCarrier(object)"
              fetch-one-url="/allegro/carrier"
              load-url="/allegro/carrier/list/pagination"
              query-url="/allegro/carrier/list/by-name"
              save-id="id"
              view-id="name"
              param="name"
              :class="{ 'is-invalid': submitted && $v.form.globalCarrier.allegroCarrierId.$error }"
          />
          <div v-if="allegroCarrier" class="text-danger">Cena minimalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMin }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="allegroCarrier" class="text-danger">Cena maksymalna przewoźnika Allegro wynosi: {{ allegroCarrier.firstItemPriceMax }} {{ allegroCarrier.firstItemPriceCurrency }}</div>
          <div v-if="submitted && !$v.form.globalCarrier.allegroCarrierId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label>Koszt dostawy</label>
          <input v-model.number="form.globalCarrier.shippingCost" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.form.globalCarrier.shippingCost.$error }" />
          <div v-if="submitted && !$v.form.globalCarrier.shippingCost.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group" v-if="carrierType === 'OVERSIZE'">
          <h4 class="text-center">Typ gabarytu</h4>

          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-10">
              <button v-for="(item, index) in packageTypes" :key="index" class="package-type" :class="{ 'active-package': form.globalCarrier.oversizeType === item, 'font-size-24': item === 'NONE' }" type="button" @click="setOversizeType(item)">{{ oversizeTypeHelper.getOversizeTypeText(item) }}</button>
            </div>
          </div>

          <input hidden :class="{ 'is-invalid': $v.form.globalCarrier.oversizeType.$error }" />
          <div v-if="!$v.form.globalCarrier.oversizeType.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="text-center">
          <b-button @click="saveChanges" variant="success">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hideCarrierModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

}

<script>
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import {oversizeTypeHelper} from "../helpers/oversize-type-helper";
import {priceHelper} from "@/helpers/price-helper";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "global-carrier-table",

  props: {
    title: {
      type: String,
      required: true
    },

    carrierType: {
      type: String,
      required: true
    },

    market: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      submitted: false,

      carriers: [],
      page: 1,

      modals: {
        globalCarrierModal: false
      },

      form: {
        globalCarrier: null
      },

      allegroCarrier: null,

      shouldUpdate: false,
      packageTypes: [ "TYPE_A", "TYPE_B", "TYPE_C", "NONE"]
    }
  },

  validations() {
    if (this.carrierType === "ECAT" || this.carrierType === "WHOLESALER") {
      return {
        form: {
          globalCarrier: {
            carrierId: {required},
            allegroCarrierId: {required},
            shippingCost: {required }
          }
        }
      }
    } else if (this.carrierType === "ECAT_ALLEGRO_SMART") {
      return {
        form: {
          globalCarrier: {
            allegroCarrierId: {required},
            shippingCost: {required}
          }
        }
      }
    } else if (this.carrierType === "OVERSIZE") {
      return {
        form: {
          globalCarrier: {
            carrierId: {required},
            allegroCarrierId: {required},
            shippingCost: {required},
            oversizeType: { required }
          }
        }
      }
    }
  },

  computed: {
    oversizeTypeHelper() {
      return oversizeTypeHelper
    },

    priceHelper() {
      return priceHelper
    },
  },

  methods: {
    changeAllegroCarrier(object) {
      this.allegroCarrier = object

      if (this.carrierType === "ECAT_ALLEGRO_SMART") {
        this.form.globalCarrier.carrierName = object.name
      }
    },

    setOversizeType(item) {
      this.form.globalCarrier.oversizeType = item
      this.$forceUpdate()
    },

    getCarrierTypeName() {
      if (this.carrierType === "OVERSIZE") {
        return "Gabaryty"
      }

      return this.carrierType.replaceAll("_", " ")
    },

    loadMore($state) {
      const perPage = 5;
      let page = this.page - 1;
      if (page > 0) {
        page = this.page * perPage - perPage;
      }

      axios.get(`/global/carrier/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          marketId: this.market.id,
          type: this.carrierType,
          page: page,
          size: perPage
        },
      }).then(({ data }) => {
        if (data.globalCarriers && data.globalCarriers.length) {
          this.page += 1;

          const globalCarriers = data.globalCarriers.map(carrier => {
            return {
              ...carrier,
              frontendId: carrier.id,
            };
          });

          this.carriers.push(...globalCarriers);
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch((error) => {
        $state.complete();
        errorCatcher.catchErrors(error)
      });
    },

    changeActiveState(globalCarrier) {
      globalCarrier.active = !globalCarrier.active
      this.shouldUpdate = true

      Swal.fire("Sukces!", globalCarrier.active ? "Aktywowano przewoźnika" : "Dezaktywowano przewoźnika", "success")
      this.$forceUpdate()
    },

    openCarrierModal(globalCarrier) {
      if (globalCarrier) {
        this.form.globalCarrier = Object.assign({}, globalCarrier)
      } else {
        this.form.globalCarrier = {
          marketId: this.market.id,
          carrierId: "",
          allegroCarrierId: "",
          oversizeType: "NONE",
          shippingCost: 0,
          oversize: false,
          active: false,
          type: this.carrierType
        }
      }

      this.modals.globalCarrierModal = true
    },

    hideCarrierModal() {
      this.allegroCarrier = null
      this.form.globalCarrier = null
      this.modals.globalCarrierModal = false
    },

    saveChanges() {
      if (this.carrierType === "ECAT_ALLEGRO_SMART" && this.allegroCarrier && this.form.globalCarrier.shippingCost < this.allegroCarrier.firstItemPriceMin) {
        Swal.fire("Błąd!", `Cena minimalna przewoźnika Allegro wynosi: ${this.allegroCarrier.firstItemPriceMin} ${this.allegroCarrier.firstItemPriceCurrency}`, "error")
        return
      }

      if (this.carrierType === "ECAT_ALLEGRO_SMART" && this.allegroCarrier && this.form.globalCarrier.shippingCost > this.allegroCarrier.firstItemPriceMax) {
        Swal.fire("Błąd!", `Cena maksymalna przewoźnika Allegro wynosi: ${this.allegroCarrier.firstItemPriceMax} ${this.allegroCarrier.firstItemPriceCurrency}`, "error")
        return
      }

      this.submitted = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.shouldUpdate = true
      Swal.fire("Sukces!", this.form.globalCarrier.id ? "Zaktualizowano przewoźnika" : "Dodano nowego przewoźnika", "success")

      if (this.form.globalCarrier.frontendId) {
        this.carriers = this.carriers.map(carrier => {
          if (carrier.frontendId === this.form.globalCarrier.frontendId) {
            return this.form.globalCarrier;
          } else {
            return carrier;
          }
        });
      } else {
        this.carriers.push({
          frontendId: uuidv4(),
          ...this.form.globalCarrier
        })
      }

      this.hideCarrierModal()
    },

    isShouldUpdate() {
      return this.shouldUpdate;
    },

    async commitChanges() {
      if (!this.shouldUpdate) {
        return
      }

      const json = {
        globalCarriers: this.carriers
      }

      try {
        const { data } = await axios.post(`/global/carrier`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await Swal.fire("Sukces!", `Zatwierdzono zmiany dla tabelki ${this.getCarrierTypeName()}`, "success")
        this.carriers = data.map(carrier => {
          return {
            ...carrier,
            frontendId: carrier.id,
          };
        });

        this.shouldUpdate = false
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  }

}
</script>

<style scoped>
.rectangle-table {
  justify-content: center;
  max-height: 600px;
  overflow-y: auto;
}

.rectangle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rectangle {
  width: 250px;
  height: 100px;
  background-color: var(--primary);
  border: 1px solid #0061d0;
  color: var(--white);
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.centered-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.ecat-left {
  text-align: left;
  margin-right: auto;
  margin-left: 5px;
  margin-top: 5px;
}

.ecat-right {
  text-align: right;
  margin-left: auto;
}
</style>